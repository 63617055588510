import tinycolor from 'tinycolor2'

const computed = getComputedStyle(document.body)
const styles = document.body.style

const dangerColor = tinycolor(computed.getPropertyValue('--button-component-danger-color'))
const linkColor = tinycolor(computed.getPropertyValue('--button-component-link-color'))
const primaryBackground = tinycolor(computed.getPropertyValue('--button-component-primary-bg'))
const secondaryColor = tinycolor(computed.getPropertyValue('--button-component-secondary-color'))

if (dangerColor.isValid()) {
	styles.setProperty('--button-component-danger-focus-shadow', dangerColor.clone().setAlpha(0.25).toString())
}

if (linkColor.isValid()) {
	styles.setProperty('--button-component-link-focus-shadow', linkColor.clone().setAlpha(0.25).toString())
}

if (primaryBackground.isValid()) {
	styles.setProperty('--button-component-primary-focus-shadow', primaryBackground.clone().setAlpha(0.25).toString())
}

if (secondaryColor.isValid()) {
	styles.setProperty('--button-component-secondary-focus-shadow', secondaryColor.clone().setAlpha(0.25).toString())
}
